<template>
  <v-app>
    <v-main class="mainbg">
      <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  
};
</script>