<template>
  <v-container>
    <v-row>
      <v-avatar :size="80" class="ml-4"
        ><img
          src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-n9lnf7pbvlma3009bf/f2cc8530-6308-11eb-918d-3d24828c498c.png"
          alt="Lucky Hookin"
      /></v-avatar>
      <div class="pl-4 d-flex flex-column justify-center">
        <div class="text-h4">Lucky Hookin</div>
        <div class="text--secondary">Hi~ 欢迎光临我的小破站!</div>
      </div>

      <v-spacer></v-spacer>

      <v-col class="d-flex align-center justify-end">
        <v-tooltip color="success" v-model="showStarTip" bottom>
          <template v-slot:activator="{}">
            <v-btn @click="onStar" class="mr-4" rounded color="primary">
              <v-icon left>mdi-heart-circle</v-icon>
              赞 {{ star }}
            </v-btn>
          </template>
          <span>👌 感谢鼓励~</span>
        </v-tooltip>

        <v-spacer class="d-flex d-sm-none"></v-spacer>
        <v-switch
          class="px-4"
          v-model="$vuetify.theme.dark"
          @click="switchShowMode"
          inset
        >
          <template v-slot:label><v-icon>mdi-brightness-4</v-icon></template>
        </v-switch>
      </v-col>
    </v-row>
    <v-row class="mt-10" v-for="apps in appsList" :key="apps.title">
      <v-col cols="12" class="text-h5 font-weight-bold">
        <v-icon left class="">{{ apps.icon }}</v-icon
        >{{ apps.title }}
      </v-col>
      <v-col cols="12" sm="6" md="4" v-for="app in apps.items" :key="app.title">
        <v-card @click="to(app)" color="primary" dark hover class="maxHeight">
          <v-card-title
            ><v-icon left>{{ app.icon }}</v-icon
            >{{ app.title }}</v-card-title
          >
          <v-card-subtitle>
            <v-col>
              {{ app.tip }}
            </v-col>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col>
        社区：<v-btn
          rounded
          color="primary"
          class="mr-5"
          @click="to({ url: juejinUrl })"
          ><v-icon left>mdi-rename-box</v-icon>掘金</v-btn
        >
        <v-btn rounded color="primary" @click="to({ url: githubUrl })"
          ><v-icon left>mdi-github</v-icon>github</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="d-flex justify-center">
        永远相信美好的事情即将发生！*⸜( •ᴗ• )⸝*
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="80vw">
      <v-card >
        <v-card-title>{{ dialogInfo.title }}</v-card-title>
        <v-card-text>
          <v-img contain max-height="70vh" :src="dialogInfo.imgUrl"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="dialogInfo.downloadUrl!=''" color="primary" rounded @click="to({url:dialogInfo.downloadUrl})">下载</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDialog = ture">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    juejinUrl: "https://juejin.cn/user/1662117312736151",
    githubUrl: "https://github.com/LuckyHookin",
    showDialog: false,
    dialogInfo: {
      title: "",
      imgUrl: "",
    },
    showStarTip: false,
    debounceF: null,
    star: 0,
    appsList: [
      {
        title: "在线工具",
        icon: "mdi-google-chrome",
        items: [
          {
            title: "简易记事本",
            icon: "mdi-notebook-edit",
            tip: "提供简单的文本编辑功能，有统计、查找、加解密等。",
            to: null,
            url: "https://edit.hookin.fun/",
          },
          {
            title: "必应壁纸集",
            icon: "mdi-image",
            tip: "自动收集必应搜索中国站的壁纸，并提供检索服务。",
            to: null,
            url: "https://bing.hookin.fun/",
          },
          {
            title: "文件分享站",
            icon: "mdi-share-variant",
            tip: "临时分享文件或文本给好友。附加功能正在开发中。",
            to: null,
            url: "https://share.hookin.fun/",
          },
        ],
      },
      {
        title: "windows 工具",
        icon: "mdi-microsoft-windows",
        items: [
          {
            title: "语音合成",
            icon: "mdi-account-voice",
            tip: "调用 edge 浏览器能力实现文本转语音，提供录制功能。",
            to: null,
            url: "https://github.com/LuckyHookin/edge-TTS-record",
          },
          {
            title: "鸿研刷屏器",
            icon: "mdi-microsoft-windows",
            tip:
              "小学到初中时期（2013-2016）开发的一款聊天刷屏器，最后一个版本为3.16.4.16071。",
            to: null,
            dialog: {
              imgUrl:
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-a2b4be01-6c86-4855-b330-a7a311dbf588/fd8ef01a-0389-483f-aa26-287616801b7a.png",
              downloadUrl:
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-a2b4be01-6c86-4855-b330-a7a311dbf588/e0eefe0b-a5ce-4868-95aa-e6ff3814e587.zip",
            },
          },
        ],
      },
      {
        title: "微信小程序",
        icon: "mdi-cellphone-text",
        items: [
          {
            title: "纪念家",
            icon: "mdi-home-heart",
            tip: "纪念美好时光！",
            to: null,
            url: null,
            dialog: {
              imgUrl:
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-a2b4be01-6c86-4855-b330-a7a311dbf588/d59c9a13-722a-4944-ab75-284287f9f4ba.jpg",
            },
          },
        ],
      },
    ],
  }),
  methods: {
    onStar() {
      this.axios.get("index/setStar").then((response) => {
        console.log(response);
        this.star = response.data.star;
      });
      this.showStarTip = true;
      this.debounceF();
    },
    to(app) {
      if (app.to != null) {
        this.$router.push(app.to);
      } else if (app.url != null) {
        window.open(app.url);
      } else if (app.dialog){
        this.dialogInfo = { title: app.title, imgUrl: app.dialog.imgUrl,downloadUrl:app.dialog.downloadUrl||'' };
        this.showDialog = true;
      }
    },
    debounce(fn, wait) {
      let timer,
        startTimeStamp = 0;

      let run = (timerInterval) => {
        timer = setTimeout(() => {
          let now = new Date().getTime();
          let interval = now - startTimeStamp;
          if (interval < timerInterval) {
            // the timer start time has been reset, so the interval is less than timerInterval
            console.log("debounce reset", timerInterval - interval);
            startTimeStamp = now;
            run(wait - interval); // reset timer for left time
          } else {
            fn();
            clearTimeout(timer);
            timer = null;
          }
        }, timerInterval);
      };

      return function () {
        let now = new Date().getTime();
        startTimeStamp = now;

        if (!timer) {
          console.log("debounce set", wait);
          run(wait); // last timer alreay executed, set a new timer
        }
      };
    },
    switchShowMode() {
      window.localStorage.removeItem("showMode");
      window.localStorage.setItem("showMode", this.$vuetify.theme.dark);
    },
  },
  created() {
    this.axios.get("index/getStar").then((response) => {
      // console.log(response);
      this.star = response.data.star;
    });
    let showMode = window.localStorage.getItem("showMode");
    switch (showMode) {
      case "true":
        this.$vuetify.theme.dark = true;
        break;
      case "false":
        this.$vuetify.theme.dark = false;
        break;
      default:
        this.$vuetify.theme.dark = false;
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          this.$vuetify.theme.dark = true;
        }
        break;
    }
    this.debounceF = this.debounce(() => {
      this.showStarTip = false;
    }, 2000);
  },
};
</script>
<style scoped>
.maxHeight {
  min-height: 100%;
}
</style>